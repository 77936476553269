/**
 * This group of functions is a near-100% copy/paste from secure-share. There may be a couple of very
 * minor differences, but the flow is pretty much identical
 */
function blockUi() {
  window.dispatchEvent(
    new CustomEvent('stripe.blockUi', {
      detail: null,
      bubbles: true,
      cancelable: true
    })
  );
}
function unBlockUi() {
  window.dispatchEvent(
    new CustomEvent('stripe.unblockUi', {
      detail: null,
      bubbles: true,
      cancelable: true
    })
  );
}

/**
 *
 * Mostly a copy-paste of the existing secure-share stripeTokenHandler.
 * The main difference is that here we don't do full window.location.href refresh
 * but instead we broadcast stripe.payment.processed message that will be caught by our
 * portal.component.ts and cause reload of the deliveryPortal record (same flow as express-based secureshare)
 */
function stripeTokenHandler(paymentId, token, stripe, card) {
  stripe
    .confirmCardPayment(paymentId, {
      payment_method: {
        card: card,
        billing_details: {
          name: document.getElementById('paywall-full-name')['value']
        }
      }
    })
    .then(function (result) {
      // refresh
      unBlockUi();
      window.dispatchEvent(
        new CustomEvent('stripe.payment.processed', {
          detail: result,
          bubbles: true,
          cancelable: true
        })
      );
    });
}

/**
 * Copy-paste of existing secure-share setupStripe function. Generates the exact same
 * stripe code-block and registers stripe components
 */
function setupStripe(stripePublicKey, paymentId) {
  var stripeSection = document.getElementById('stripePayment');
  stripeSection.style.display = 'block';
  var stripe = Stripe(stripePublicKey);
  if (stripe == null) {
    return;
  }

  var form = document.getElementById('payment-form');

  var elements = stripe.elements();

  // Custom styling can be passed to options when creating an Element.
  var style = {
    base: {
      display: 'block',
      width: '100 %',
      height: 'calc(1.25em + 0.75rem + 2px)',
      padding: '0.375rem 0.75rem',
      fontSize: '0.9rem',
      fontWeight: '500',
      lineHeight: '1.25',
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #d7d7db',
      borderRadius: '0.25rem',
      '-webkit-transition': 'border - color 0.15s ease -in -out, box - shadow 0.15s ease -in -out',
      transition: 'border - color 0.15s ease -in -out, box - shadow 0.15s ease -in -out'
    }
  };

  // Create an instance of the card Element.
  var card = elements.create('card');

  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');

  // Create a token or display an error when the form is submitted.

  //alert('adding submit')
  form.addEventListener('submit', function (event) {
    event.preventDefault();
    var billingName = document.getElementById('paywall-full-name')['value'];

    if (!billingName || billingName.replace(' ', '').length === 0) {
      document.getElementById('paywall-full-name').classList.add('StripeElement--invalid');
      return;
    }
    document.getElementById('paywall-full-name').classList.remove('StripeElement--invalid');
    blockUi();

    stripe
      .createToken(card)
      .then(function (result) {
        if (result.error) {
          unBlockUi();

          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          stripeTokenHandler(paymentId, result.token, stripe, card);
        }
      })
      .catch(function (error) {
        unBlockUi();
        // TODO: Need to make this better - maybe throw a general exception page
        // PS This would usually happen in some hard stripe errors - like calls with invalid payment ID, etc
        // Also we need to figre out how to log it - probably create a POST method in our server routes that accepts error
        // then call it via ajax and then have the server log it
        alert('Unexpected error while trying to process your payment');
      });
  });
}
/**
 * For now twisties also use basic JS from secure-share. This is the same as "toggleTwisy" function in secure-share
 */
function toggleTwistyGlobal(id, collapsedTitle, expandedTitle) {
  const twisty = document.getElementById(id);
  const twistyLinkId = id.substring(0, id.length - 7) + 'link';
  const twistyLink = document.getElementById(twistyLinkId);
  let actionTaken = 'expanded';
  if (twisty) {
    if (twisty.className == 'collapse') {
      twisty.className = 'collapse show';
      twistyLink.classList.remove('collapsed');
    } else {
      actionTaken = 'collapsed';
      twisty.className = 'collapse';
      twistyLink.classList.add('collapsed');
    }
    if (collapsedTitle && expandedTitle) {
      let twistyParent = document.getElementById(id.replace('-content', '-link'));

      if (twistyParent) {
        twistyParent.textContent = twisty.className == 'collapse' ? collapsedTitle : expandedTitle;
      }
    }
  }
  try {
    var evt = window.event || arguments.callee.caller.arguments[0];
    // only record if we either can't get the event info or we know it was triggered by the cursor
    // evt.isPrimary === undefined when we call this manually via js
    if (appInsights && (!evt || evt.isPrimary !== undefined)) {
      appInsights.trackEvent({ name: 'Twisty "' + id + '" ' + actionTaken });
    }
  } catch (e) {
    // it's just a logging failure, don't log
  }
}
